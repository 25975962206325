import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import H5 from 'components/H5';
import FormError from 'components/FormError';
import Line from 'components/Line';
import Icon from '../../components/Icon';
import Switch from 'components/Switch';
import ListEmpty from '../../components/ListEmpty';

const InputContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default class DetailsForm extends Component {
  state = {
    selectedUser: '',
  };

  handleSelectChange = event => {
    const { value } = event.target;
    this.setState({ selectedUser: value });
  };

  submit = values => {
    const { onSubmit } = this.props;
    const activeTab = 'Accounts';

    onSubmit(values, activeTab);
  };

  componentDidMount() {
    const { getCities, initialValues, isEditing } = this.props;

    if (initialValues.state_id) {
      getCities(initialValues.state_id);
    }

    this.setState({
      selectedUser: isEditing
        ? initialValues.admins.filter(admin => !admin.deleted).length === 0
          ? 'add_later'
          : ''
        : 'new_user',
    });
  }

  render() {
    const {
      initialValues,
      isInitialValid,
      states,
      cities,
      getCities,
      isEditing,
      adminsBrands,
    } = this.props;
    const { selectedUser } = this.state;

    return (
      <Formik
        enableReinitialize
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          address_line: Yup.string().required('Required'),
          admin_email: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string()
              .required('Required')
              .email('Invalid Format'),
            otherwise: Yup.string().email('Invalid Format'),
          }),
          admin_first_name: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          admin_last_name: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          admin_phone_number: Yup.string().when('createNewUser', {
            is: true,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          admins: Yup.array(),
          city_id: Yup.string().required('Required'),
          createNewUser: Yup.boolean(),
          isPointOfContact: Yup.boolean(),
          contact_account: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          contact_email: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string()
              .required('Required')
              .email('Invalid Format'),
            otherwise: Yup.string().email('Invalid Format'),
          }),
          contact_first_name: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          contact_last_name: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          contact_phone_number: Yup.string().when('isPointOfContact', {
            is: false,
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
          }),
          name: Yup.string().required('Required'),
          state_id: Yup.string().required('Required'),
          zip_code: Yup.string()
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, {
              message: 'Invalid zipcode',
            })
            .required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isValid }) => (
          <Form>
            <Row style={{ marginTop: '20px' }}>
              <Column style={{ marginRight: '15px' }} />
              <Column style={{ marginLeft: '15px' }}>
                <H2 inputLabel color={Colors.lightBlue}>
                  Brand admin
                </H2>
                <H5
                  color={Colors.blue}
                  textAlign="left"
                  style={{
                    padding: '5px',
                    paddingLeft: '15px',
                    backgroundColor: '#F0F6F9',
                    marginTop: '5px',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    The brand admin would have access to the administrator platform to check the
                    devices status.
                  </span>
                  You can add the brand admin later.
                </H5>
              </Column>
            </Row>
            <Row>
              <Column style={{ marginRight: '15px' }}>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Name
                    </H4>
                    <Input
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer style={{ paddingRight: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Address line
                    </H4>
                    <Input
                      placeholder="Address line"
                      name="address_line"
                      value={values.address_line}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError
                      text={errors.address_line && touched.address_line && errors.address_line}
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer style={{ paddingRight: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      Address line 2
                    </H4>
                    <Input
                      placeholder="Address line 2"
                      name="address_line_1"
                      value={values.address_line_1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError
                      text={
                        errors.address_line_1 && touched.address_line_1 && errors.address_line_1
                      }
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <Column style={{ paddingRight: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      State
                    </H4>
                    <Select
                      placeholder="State"
                      name="state_id"
                      options={states}
                      value={values.state_id}
                      onChange={evt => {
                        getCities(evt.target.value);
                        handleChange(evt);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.state_id && touched.state_id && errors.state_id} />
                  </Column>
                  <Column style={{ paddingRight: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      City
                    </H4>
                    <Select
                      placeholder="City"
                      name="city_id"
                      options={cities}
                      value={values.city_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.city_id && touched.city_id && errors.city_id} />
                  </Column>
                </Row>
                <Row>
                  <Column style={{ paddingRight: '10px', paddingTop: '15px' }}>
                    <H4 inputLabel color={Colors.gray8}>
                      ZIP
                    </H4>
                    <Input
                      placeholder="ZIP"
                      name="zip_code"
                      type="number"
                      value={values.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="map-marker-alt"
                    />
                    <FormError text={errors.zip_code && touched.zip_code && errors.zip_code} />
                  </Column>
                </Row>
              </Column>
              <Column style={{ marginLeft: '15px' }}>
                <div style={{ paddingTop: '26px' }}>
                  {isEditing ? (
                    <FieldArray
                      name="admins"
                      render={arrayHelpers => (
                        <div>
                          {values.admins.map((admin, index) => {
                            if (admin.deleted) return false;
                            return (
                              <Row
                                key={index}
                                style={{
                                  padding: '5px 0',
                                }}
                              >
                                <Column
                                  style={{
                                    width: '90%',
                                  }}
                                >
                                  <Input disabled value={admin.label} />
                                </Column>
                                <Column
                                  style={{
                                    width: '10%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Icon
                                    color={Colors.red}
                                    name="trash"
                                    style={{ cursor: 'pointer' }}
                                    fontSize={17}
                                    onClick={() => {
                                      const currentAdmins = values.admins;
                                      if (admin.new) {
                                        arrayHelpers.remove(index);
                                      } else {
                                        arrayHelpers.replace(index, {
                                          ...admin,
                                          deleted: true,
                                        });
                                      }
                                      const hasActiveAdmins = currentAdmins.some(
                                        (admin, i) => i !== index && !admin.deleted,
                                      );
                                      if (!hasActiveAdmins) {
                                        setFieldValue('isPointOfContact', false);
                                      }
                                    }}
                                  />
                                </Column>
                              </Row>
                            );
                          })}
                          {values.admins.filter(admin => !admin.deleted).length === 0 && (
                            <Select
                              placeholder="Select some brands..."
                              options={[
                                { label: 'New User', value: 'new_user' },
                                { label: 'Add later', value: 'add_later' },
                                ...adminsBrands,
                              ]}
                              name="select_user"
                              value={selectedUser}
                              onChange={event => {
                                this.handleSelectChange(event);
                                const optionSelected = [event.target.value];
                                if (optionSelected.includes('new_user')) {
                                  setFieldValue('createNewUser', true);
                                } else if (optionSelected.includes('add_later')) {
                                  setFieldValue('createNewUser', false);
                                  setFieldValue('isPointOfContact', false);
                                  setFieldValue('brand_admin_id', null);
                                } else {
                                  setFieldValue('createNewUser', false);
                                  setFieldValue('brand_admin_id', parseInt(event.target.value, 10));
                                }
                              }}
                              onBlur={handleBlur}
                            />
                          )}
                        </div>
                      )}
                    />
                  ) : (
                    <Select
                      placeholder="Select option..."
                      options={[
                        { label: 'New User', value: 'new_user' },
                        { label: 'Add later', value: 'add_later' },
                        ...adminsBrands,
                      ]}
                      name="select_user"
                      value={selectedUser}
                      onChange={event => {
                        this.handleSelectChange(event);
                        const optionSelected = [event.target.value];
                        if (optionSelected.includes('new_user')) {
                          setFieldValue('createNewUser', true);
                        } else if (optionSelected.includes('add_later')) {
                          setFieldValue('createNewUser', false);
                          setFieldValue('isPointOfContact', false);
                          setFieldValue('brand_admin_id', null);
                        } else {
                          setFieldValue('createNewUser', false);
                          setFieldValue('brand_admin_id', parseInt(event.target.value, 10));
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  )}
                </div>

                {selectedUser === 'new_user' && (
                  <>
                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      First name
                    </H4>
                    <Input
                      placeholder="First name"
                      name="admin_first_name"
                      value={values.admin_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError
                      text={
                        errors.admin_first_name &&
                        touched.admin_first_name &&
                        errors.admin_first_name
                      }
                    />

                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      Last name
                    </H4>
                    <Input
                      placeholder="Last name"
                      name="admin_last_name"
                      value={values.admin_last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError
                      text={
                        errors.admin_last_name && touched.admin_last_name && errors.admin_last_name
                      }
                    />

                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      Email
                    </H4>
                    <Input
                      placeholder="Email"
                      name="admin_email"
                      value={values.admin_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="envelope"
                    />
                    <FormError
                      text={errors.admin_email && touched.admin_email && errors.admin_email}
                    />

                    <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                      Phone number
                    </H4>
                    <Input
                      placeholder="Phone number"
                      name="admin_phone_number"
                      value={values.admin_phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="phone"
                    />
                    <FormError
                      text={
                        errors.admin_phone_number &&
                        touched.admin_phone_number &&
                        errors.admin_phone_number
                      }
                    />
                  </>
                )}

                <div style={{ marginTop: '15px', padding: '15px', backgroundColor: '#FBFBFB' }}>
                  <H4 style={{ fontWeight: 'bold', paddingBottom: '10px' }} color={Colors.black2}>
                    Point of contact
                  </H4>
                  {selectedUser === 'add_later' ? (
                    <H5
                      color={Colors.blue}
                      textAlign="left"
                      style={{
                        padding: '5px',
                        paddingLeft: '15px',
                        backgroundColor: '#F0F6F9',
                        marginTop: '5px',
                      }}
                    >
                      If you are not creating a location admin, location point of contact
                      information must be entered.
                    </H5>
                  ) : (
                    <Switch
                      name="isPointOfContact"
                      isChecked={values.isPointOfContact}
                      titleChecked="Use brand admin as point of contact"
                      titleUnchecked="Use brand admin as point of contact"
                      onChange={handleChange}
                    />
                  )}

                  {!values.isPointOfContact && (
                    <div style={{ paddingTop: '15px' }}>
                      <H4 inputLabel color={Colors.gray8}>
                        Account
                      </H4>
                      <Input
                        placeholder="Account"
                        name="contact_account"
                        value={values.contact_account}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="stream"
                      />
                      <FormError
                        text={
                          errors.contact_account &&
                          touched.contact_account &&
                          errors.contact_account
                        }
                      />
                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        First name
                      </H4>
                      <Input
                        placeholder="First name"
                        name="contact_first_name"
                        value={values.contact_first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="user"
                      />
                      <FormError
                        text={
                          errors.contact_first_name &&
                          touched.contact_first_name &&
                          errors.contact_first_name
                        }
                      />

                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        Last name
                      </H4>
                      <Input
                        placeholder="Last name"
                        name="contact_last_name"
                        value={values.contact_last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="user"
                      />
                      <FormError
                        text={
                          errors.contact_last_name &&
                          touched.contact_last_name &&
                          errors.contact_last_name
                        }
                      />

                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        Email
                      </H4>
                      <Input
                        placeholder="Email"
                        name="contact_email"
                        value={values.contact_email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="envelope"
                      />
                      <FormError
                        text={errors.contact_email && touched.contact_email && errors.contact_email}
                      />

                      <H4 inputLabel color={Colors.gray8} style={{ paddingTop: '15px' }}>
                        Phone number
                      </H4>
                      <Input
                        placeholder="Phone number"
                        name="contact_phone_number"
                        value={values.contact_phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        icon="phone"
                      />
                      <FormError
                        text={
                          errors.contact_phone_number &&
                          touched.contact_phone_number &&
                          errors.contact_phone_number
                        }
                      />
                    </div>
                  )}
                </div>
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>
            <Row>
              <ButtonContainer>
                <ButtonText text="Next" theme="primary" type="submit" disabled={!isValid} />
              </ButtonContainer>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}
